<template lang="">
  <div>
    <b-card v-if="showFilter">
      <h1>Filter</h1>
      <b-row>
        <b-col cols="8">
          <form-v-select
            ref="vendor"
            v-model="vendor"
            dataurl="vendor?filter[approval_status]=1"
            col-label="3"
            label="Vendor"
            placeholder="Semua Vendor"
            :item_text="'name'"
          />
        </b-col>
        <b-col cols="8">
          <b-form-group
            label-cols-md="3"
            label="Periode"
          >
            <b-input-group>
              <b-form-input
                v-model="year"
                type="number"
                placeholder="Periode Tahun"
              />
              <b-input-group-append is-text>
                Year
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="8">
          <b-form-group
            label-cols-md="3"
            label="Status"
          >
            <b-form-select
              v-model="status"
              :options="[
                { value: null, text: 'Semua Status' },
                { value:'draft',text:'Draft'},
                { value:'final',text:'Final'}
              ]"
            />
          </b-form-group>
        </b-col>
        <b-col cols="8">
          <b-form-group
            label-cols-md="3"
            label=""
          >
            <b-button
              class="mr-1"
              size="sm"
              variant="primary"
              type="button"
              @click.prevent="show"
            >Show</b-button>
            <b-button
              class="mr-1"
              size="sm"
              variant="outline-secondary"
              type="button"
              @click.prevent="reset"
            >Reset</b-button>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <basetable
        ref="basetable"
        :dataurl="dataurl"
        :fields="fields"
        :baseroute="baseroute"
        :title="title"
        :filter="filter"
        :is-edit="false"
        :is-delete="false"
      >
        <template #cell(status)="data">
          <span
            class="badge "
            :class="data.item.status == 'final'?'bg-primary':'bg-warning'"
          >
            {{ data.item.status == 'final'?'Final':'Draft' }}
          </span>
        </template>
        <template #cell(nominal)="data">
          Rp {{data.item.nominal|numFormat}}
        </template>
        <template #cell(remnant_skko)="data">
          Rp {{(data.item.nominal-getUsedPayment(data.item))|numFormat}}
        </template>
        <template #cell(used_skko)="data">
          Rp {{getUsedPayment(data.item)|numFormat}}
        </template>
        <template v-slot:buttonaddon>
          <b-button
            class="mr-1"
            size="sm"
            variant="success"
            @click="showFilter =!showFilter"
          >
            <feather-icon icon="FilterIcon" />
            Filter
          </b-button>
        </template>
      </basetable>
    </b-card>
  </div>
</template>
<script>
import basetable from '@/views/base/BaseTable.vue'
import { BCard, BButton, BFormSelect, BFormGroup, BRow,BCol, BFormInput, BInputGroup, BInputGroupAppend } from 'bootstrap-vue'
import FormVSelect from '@/views/base/form/FormVSelect.vue'

export default {
  components: {
    basetable,
    BCard,
    BButton,
    BFormSelect,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    FormVSelect,
    BInputGroup,
    BInputGroupAppend
  },
  data() {
    return {
      dataurl:"/skko",
      baseroute:"skko",
      title:"Daftar SKKO",
      fields: [
        { key: 'number', label: 'Code', sortable: true },
        { key: 'vendor.name', label: 'Vendor', sortable: true },
        // { key: 'date_start', label: 'Tanggal Awal', sortable: true },
        // { key: 'date_end', label: 'Tanggal Akhir', sortable: true },
        { key: 'year', label: 'Tahun', sortable: true },
        { key: 'nominal', label: 'Nominal', sortable: true },
        { key: 'used_skko', label: 'Terpakai', sortable: true },
        { key: 'remnant_skko', label: 'Sisa', sortable: true },
        // { key: 'description', label: 'Keterangan', sortable: true },
        { key: 'status', label: 'Status', sortable: true }
      ],
      vendor:null,
      status:null,
      year:null,
      showFilter : false,
      filter :[],
    }
  },
  methods:{
    show(){
      this.filter['filter[vendor_id]']=this.vendor?this.vendor.id:null
      this.filter['filter[year]']=this.year?this.year:null
      this.filter['filter[status]']=this.status
      this.$refs.basetable.fetchData()
    },
    reset(){
      this.vendor = null
      this.year = null
      this.status = null
      this.show()
    },
    getUsedPayment(data){
      var total_price = 0
      if(data.spbs){
        var spbs = data.spbs
        spbs.forEach(spb => {
          if(spb.billings){
            var billings = spb.billings
            billings.forEach(billing => {
              if(billing.details){
                var details = billing.details
                details.forEach(detail => {
                  if(detail.status == 'paid'){
                    total_price += (parseInt(detail.amount)+(parseInt(detail.amount)*0.1))
                  }
                })
              }
            })
          }
        })
      }
      return total_price
    },
  }
}
</script>
<style lang="">

</style>
